<template>
  <div :class="customClass" class="w-32 v2_date_filter">
    <input
      :class="className"
      :value="date.label"
      @input="this.$emit('input', date)"
      readonly
      placeholder="Time"
      type="text"
    >
    <i class="fal color-[#757A8A] text-[1rem] font-semibold fa-angle-down"></i>
  </div>
</template>
<script>

export default {
  props: ['className', 'customClass'],
  data() {
    return {
      date: ''
    }
  },
  mounted () {
    let self = this
    setTimeout(function () {
      self.callDatePicker()
    }, 500)
  },
  methods: {
    callDatePicker () {
      let me = this
      $(document).ready(function () {
        $(`.${me.className}`).daterangepicker({
          opens: 'left',
          parentEl: 'body',
          maxDate: moment(),
          autoUpdateInput: false,
          startDate: moment().startOf('day').subtract(1, 'hour'),
          endDate: moment().endOf('day').subtract(1, 'hour'),
          ranges: {
            ' All Time': [moment().startOf('day').subtract(1, 'hour'), moment().endOf('day').subtract(1, 'hour')],
            ' Today': [moment(), moment()],
            ' Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            ' Last 7 Days': [moment().subtract(6, 'days'), moment()],
            ' Last 30 Days': [moment().subtract(29, 'days'), moment()],
            ' This Month': [moment().startOf('month'), moment().endOf('month')],
            ' Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            ' Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
          }
        })
        $(`.${me.className}`).on('show.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').addClass('shown')
        })

        $(`.${me.className}`).on('hide.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').removeClass('shown')
        })

        $(`.${me.className}`).on('apply.daterangepicker', function (ev, picker) {
          let range = null
          if (picker.chosenLabel != ' All Time') {
            range = {
              label: picker.chosenLabel,
              value: picker.startDate.format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + picker.endDate.format('YYYY-MM-DDTHH:mm:ss')
            }
          } else {
            range = {
              label: picker.chosenLabel,
              value: moment().subtract(5, 'years').format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + moment().format('YYYY-MM-DDTHH:mm:ss')
            }
          }
          me.date = range;
          me.$emit('input', range)
        })
      })
    },
  }
}
</script>
<style lang="less">
.v2_date_filter {
  border-radius: 5px;
  padding: 0 10px;
  font-family: poppins;
  color: #757A8A;
  display: flex;
  align-items: center;
  input {
    border:none;
    padding: 0;
    height: 1.850rem;
    width: 100%;
    margin-right: 5px;
    font-size: 0.875rem;
    font-weight: 500;
    color: #757A8A;
    &:focus {
      border-color: transparent;
    }
  }
  input::placeholder {
    color: #757A8A !important;
  }
}

.with_border {
  border: 1px solid #757A8A;
}

.without_border {
  border: 1px solid #ECEEF5;
  background-color: #F2F3F8 !important;
  input {
    background-color: #F2F3F8;
  }
}

.without_border_transparent {
  border: none;
  background-color: transparent !important;
  input {
    background-color: transparent;
  }
}


.daterangepicker {
  border-radius: 8px 0px 8px 8px;
  &:before, &:after {
    display: none;
  }
  padding: 0.25rem 0;
  .ranges {
    ul {
      width: 13.688rem;
      border-right: 8px;
      li {
        padding: 0.75rem 1rem;
        border: none;
        color: #3C4549 !important;
        background: white;
        font-size: 0.875rem !important;
        font-family: Poppins !important;
        margin-bottom: 0;
        &:hover {
          background: #F4F6FA;
          border: none;
        }
      }
      li.active {
        background-color: #F4F6FA !important;
        border: none;
      }
    }
  }
}
</style>
